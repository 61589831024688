// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

interface EnvironmentModel {
  production: boolean;
  backend: string;
  domain: domain;
  powerBI: {
    backend: string;
  };
  load: {
    url: string;
  };
  azure_maps: {
    key: string;
  };
  primavera_analytics: {
    url: string;
  };
  version: {
    version: string;
    type: versionType;
    patch?: string;
    date?: string;
  };
  api_microservice_url: string;
  api_gateway_url: string;
  services: {
    daily_assistance: string;
    assignment_pod: string;
    validation_pod: string;
    punchlist: string;
  };
}

type domain =
  null
  | 'tecnasic'
  | 'cmp'
  | 'mlp'
  | 'llorente'
  | 'aima'
  | 'antamina'
  | 'humeres'
  | 'promet'
  | 'aima-contratista'
  | 'aima-mandante'
  | 'cmp-interno' | 'entrenamiento-contratista'
  | 'sub-contratistas' | 'ipproyectos' | 'sigma' | 'dev-mandante' | 'subcontratistas' | 'kipreos';
type versionType = 'stable' | 'alpha' | 'beta' | 'rc' | 'patch' | 'date';

export const environment: EnvironmentModel = {
  production: false,
  // backend: 'http://localhost:8000',
  // backend: 'http://192.168.193.179:8000', // -> vpn de gary
  backend: 'https://website-back-testing-sl265.ondigitalocean.app',
  domain: 'aima',
  powerBI: {
    backend: 'https://power-bi-embed-token.aimacloud.app'
  },
  load: {
    url: 'https://upload-data-go-dot-tecnasic-317820.uc.r.appspot.com'
    // url: 'http://192.168.193.93:5000'
  },
  primavera_analytics: {
    url: 'https://com-aima-ai-int-oracle-primavera-vcsn4.ondigitalocean.app/'
  },
  azure_maps: {
    key: 'tz7nEM_3cy2r2KLKQ0VF6NPiNijQc8plxtb-fsy0a64'
  },
  version: {
    version: '1.8.12',
    type: 'alpha'
  },
  api_gateway_url: "https://g7szt79v7g.execute-api.us-east-1.amazonaws.com/dev",
  api_microservice_url: "https://aima-microservices-release.azure-api.net",
 // api_microservice_url: "http://172.30.119.224:8000",
  services: {
    daily_assistance: 'https://int-daily-assistance-testing-q8sd5.ondigitalocean.app',
    assignment_pod: 'https://assingments-pod-testing-yttey.ondigitalocean.app', //testing
    validation_pod: 'https://validation-assignment-worked-mbgsc.ondigitalocean.app', //testing
    punchlist: 'https://com-aima-ai-bsl-ms-puchlist-go-vbg5t.ondigitalocean.app',
    //punchlist: 'http://192.168.193.179:8080' //vpn gary
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
